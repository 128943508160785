import { payloadToWidgetAdapter } from 'lib/adapters/widgetPayloadAdapter'
import { LogEvents, LogEventsType } from 'lib/types/log-events'
import { PusherEvents, PusherEventsType } from 'lib/types/pusher-events'
import { AdvertisingMode, IWidget } from 'lib/types/widget'
import moment from 'moment'
import cachedLog from 'views/WidgetOld/Debug/cachedLog'
import { defaultWidget } from 'Widget/constants/default-widget'

export default class WidgetLogger {
  slug: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log: any = [];
  widget: IWidget = defaultWidget;
  mode: AdvertisingMode = AdvertisingMode.AUTO;
  onPushLog?: () => void

  constructor (slug: string, params: { onPushLog?: () => void }) {
    this.slug = slug
    this.log = cachedLog.get(this.slug)
    this.onPushLog = params?.onPushLog
  }

  get value () {
    return this.log
  }

  set value (value) {
    this.log = value
  }

  setMode (mode: AdvertisingMode) {
    this.mode = mode
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleEvent (event: PusherEventsType, payload: any) {
    let newWidget

    switch (event) {
    case PusherEvents.WIDGET_UPDATED:
      delete payload.status // status was add to widget in socket
      newWidget = payloadToWidgetAdapter(payload)

      if (!this.compareWidgets({ ...newWidget }, { ...this.widget })) {
        this.pushLog(LogEvents.UPDATE_WIDGET, {
          event,
          payload: newWidget,
          oldWidget: this.widget,
        })
        this.widget = newWidget
      }
      break

    case PusherEvents.MANUAL_LAUNCH:
      this.pushLog(LogEvents.MANUAL_START, { event, payload })
      break

    case PusherEvents.AUTO_LAUNCH:
      this.pushLog(LogEvents.FETCHED_CREATIVES, { event, payload })
      if (this.mode === AdvertisingMode.AUTO && payload.status) {
        this.pushLog(LogEvents.AUTO_START, { event })
      }
      break

    case PusherEvents.REFERRAL_LAUNCH:
      this.pushLog(LogEvents.FETCHED_REFERRAL_CREATIVES, { event, payload })
      if (this.mode === AdvertisingMode.AUTO && payload.status) {
        this.pushLog(LogEvents.AUTO_START, { event })
      }
      break

    case PusherEvents.DEMO_LAUNCH:
      this.pushLog(LogEvents.FETCHED_DEMO_CREATIVES, { event, payload })
      this.pushLog(LogEvents.DEMO_START, { event, payload })
      break

    case PusherEvents.CHATBOT_MESSAGE_SENT:
      this.pushLog(LogEvents.CHATBOT_MESSAGE_SENT, { event, payload })
      break

    case PusherEvents.FETCHING_AD:
      this.pushLog(LogEvents.FETCHING_AD, { event, payload })
      break

    default: break
    }
  }

  compareWidgets (widget: IWidget, oldWidget: IWidget) {
    widget.streamer = null
    oldWidget.streamer = null

    return JSON.stringify(widget) === JSON.stringify(oldWidget)
  }

  pushLog (
    event: LogEventsType,
    {
      payload,
      creatives,
      oldWidget,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }: any,
  ) {
    const dateTime = moment().format('HH:mm:ss')

    this.log.push({
      dateTime,
      event,
      creatives,
      oldWidget,
      payload,
    })
    cachedLog.save(this.slug, this.log)
    if (this.onPushLog) {
      this.onPushLog()
    }
  }

  deleteLog () {
    cachedLog.delete(this.slug)
  }
}
