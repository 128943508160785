function escapeRegExp (str) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&') // eslint-disable-line
}

export default data => {
  const stringifyedData = JSON.stringify(data)
  const splitted = stringifyedData.split('"')
  // console.log(splitted)

  const overlaps = {}
  splitted.forEach(item => {
    if (overlaps[item]) {
      overlaps[item]++
    }
    else {
      overlaps[item] = 1
    }
  })
  const sortedOverlaps = Object.keys(overlaps).sort((a, b) => b.length - a.length)
  // console.log(overlaps, sortedOverlaps)

  let compressed = stringifyedData
  const keys = {}
  sortedOverlaps.forEach((overlap, i) => {
    const isShortAndFrequent = overlaps[overlap] > 1 && overlap.length > 5
    const isLongAndRare = overlaps[overlap] > 3 && overlap.length > 2

    if (isShortAndFrequent || isLongAndRare) {
      const key = `{${i}}`
      compressed = compressed.replace(new RegExp(escapeRegExp(overlap), 'g'), key)
      keys[key] = overlap
    }
  })
  // console.log(compressed)
  // console.log(keys)

  return {
    compressed,
    keys,
  }
}
