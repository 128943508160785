import compress from 'views/WidgetOld/Debug/compress'
import decompress from 'views/WidgetOld/Debug/decompress'

const lifespan = 14 * 24 * 60 * 60 * 1000

function saveLog (slug, log) {
  const widgets = getCachedWidgets() || {}
  widgets[slug] = {
    updated: new Date().getTime(),
    log: compress(log.slice(-200)),
  }
  cacheWidgets(widgets)
}

function deleteLog (slug) {
  const widgets = getCachedWidgets()
  delete widgets[slug]
  cacheWidgets(widgets)
}

function getCachedLog (slug) {
  const widgets = getCachedWidgets()
  if (!widgets?.[slug]) return []
  return decompress(widgets[slug].log)
}

function getCachedWidgets () {
  if (!localStorage.getItem('debug-widgets')) return null
  return JSON.parse(localStorage.getItem('debug-widgets'))
}

function cacheWidgets (widgets) {
  localStorage.setItem('debug-widgets', JSON.stringify(widgets))
}

function clearExpired () {
  if (!localStorage.getItem('debug-widgets')) return null
  const widgets = JSON.parse(localStorage.getItem('debug-widgets'))
  for (const slug in widgets) {
    const expired = widgets[slug].updated + lifespan
    const now = new Date().getTime()
    if (now > expired) {
      deleteLog(slug)
    }
  }
}

export default {
  save: saveLog,
  delete: deleteLog,
  get: getCachedLog,
  cacheWidgets,
  getCachedWidgets,
  clearExpired,
}
