<template>
  <div class="debug-send-preview">
    <StreamerSearcher
      :disabled="disabled"
      :value="[]"
      @input="onInput"
    />
  </div>
</template>

<script>
import StreamerSearcher from 'components/form-items/StreamerSearcher'

export default {
  components: {
    StreamerSearcher,
  },

  props: {
    disabled: VueTypes.bool,
  },

  methods: {
    onInput (streamers) {
      if (!streamers.length) return
      this.$emit('select', streamers[0].widget.slug)
    },
  },
}
</script>

<style lang="scss" scoped>
.debug-send-preview {
  ::v-deep {
    .el-button {
      width: 100%;
    }

    .el-tag {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
