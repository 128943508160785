<template>
  <div class="debug-log-no-streamer">
    <div class="debug-log-no-streamer__title">
      Streamer not found
    </div>
    <ElButton
      type="danger"
      @click="$emit('close')"
    >
      Remove
    </ElButton>
  </div>
</template>

<script>
import ElButton from 'element/ElButton'

export default {
  components: {
    ElButton,
  },
}
</script>

<style lang="scss" scoped>
.debug-log-no-streamer {
  width: 100%;
  text-align: center;
  margin-top: 50px;

  &__title {
    margin-bottom: 12px;
  }
}
</style>
