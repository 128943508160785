<template>
  <div class="widget-states">
    <div
      v-for="item in states"
      :key="item.label"
    >
      <b
        class="widget-states__label"
        :class="{'widget-states__label_changed': item.changed}"
      >
        {{ item.label }}
      </b>:
      <span
        class="widget-states__value"
        :class="`widget-states__value_${item.status}`"
      >{{ item.value }}</span>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get'

export default {
  props: {
    widget: VueTypes.object,
    oldWidget: VueTypes.oneOfType([VueTypes.object, VueTypes.nullType]).def(null),
  },
  computed: {
    categories: ({ $store }) => $store.getters['dictionaries/campaignDictionaries'].categories,
    states ({ widget, categories }) {
      const ignored = widget?.ignoreCategories?.map(ctg => {
        if (categories) {
          const category = categories.find(item => item.id === ctg)
          if (category) return category.title
        }
      })

      return [
        {
          label: 'Widget',
          value: widget?.enabled ? 'active' : 'not active',
          status: widget?.enabled,
          changed: this.checkChanging('enabled'),
        },
        {
          label: 'Stream',
          value: widget?.stream?.enabled ? 'active' : 'not active',
          status: widget?.stream?.enabled,
          changed: this.checkChanging('stream.enabled'),
        },
        {
          label: 'Manual ad button',
          value: widget?.adManualEnabled ? 'active' : 'not active',
          status: widget?.adManualEnabled,
          changed: this.checkChanging('adManualEnabled'),
        },
        {
          label: 'Chatbot',
          value: widget?.botEnabled ? 'active' : 'not active',
          status: widget?.botEnabled,
          changed: this.checkChanging('botEnabled'),
        },
        {
          label: 'Chatbot connected',
          value: get(widget, `nightbot.${widget?.platform}.connected`) ? 'yes' : 'no',
          status: get(widget, `nightbot.${widget?.platform}.connected`),
          changed: this.checkChanging(`nightbot.${widget?.platform}.connected`),
        },
        {
          label: 'Chatbot moderator',
          value: get(widget, `nightbot.${widget?.platform}.moderator`) ? 'yes' : 'no',
          status: get(widget, `nightbot.${widget?.platform}.moderator`),
          changed: this.checkChanging(`nightbot.${widget?.platform}.moderator`),
        },
        {
          label: 'Mode',
          value: widget?.advertising?.mode || '—',
          changed: this.checkChanging('advertising.mode'),
        },
        {
          label: 'Stream delay',
          value: `${widget?.stream?.delay}s` || '—',
          changed: this.checkChanging('stream.delay'),
        },
        {
          label: 'Frequency',
          value: `${widget?.advertising?.frequency || '0'} mins`,
          changed: this.checkChanging('advertising.frequency'),
        },
        {
          label: 'Leaderboard position',
          value: widget?.leaderboard?.position,
          changed: this.checkChanging('leaderboard.position'),
        },
        {
          label: 'PIP position',
          value: widget?.advertising?.position,
          changed: this.checkChanging('advertising.position'),
        },
        {
          label: 'Ignore categories',
          value: ignored?.join(', ') || 'none',
          changed: this.checkChanging('ignoreCategories'),
        },
        {
          label: 'Subscribes status',
          value: widget?.subscribes?.status ? 'active' : widget?.subscribes?.reason ? `not active (${widget?.subscribes?.reason})` : 'not active',
          status: widget?.subscribes?.status,
          changed: this.checkChanging('subscribes'),
        },
        {
          label: 'Subscribes',
          value: widget?.subscribes?.subscribes?.subscribes ? widget?.subscribes?.subscribes.join(', ') : 'none',
          changed: this.checkChanging('subscribes_list'),
        },
        {
          label: 'Application',
          value: widget?.subscribes?.application_name ? widget?.subscribes?.application_name : 'none',
          changed: this.checkChanging('subscribes'),
        },
      ]
    },
  },

  methods: {
    checkChanging (param) {
      if (!this.widget || !this.oldWidget) return false
      if (param === 'ignoreCategories') {
        const newValue = JSON.stringify(get(this.widget, param))
        const oldValue = JSON.stringify(get(this.oldWidget, param))
        return newValue !== oldValue
      }
      if (param === 'subscribes_list') {
        const newValue = JSON.stringify(this.widget?.subscribes?.subscribes)
        const oldValue = JSON.stringify(this.oldWidget?.subscribes?.subscribes)
        return newValue !== oldValue
      }
      return get(this.widget, param) !== get(this.oldWidget, param)
    },
  },
}
</script>

<style lang="scss" scoped>
.widget-states {
  max-width: 300px;

  * {
    line-height: 1.5;
  }

  &__label {
    font-weight: 600;
    position: relative;

    &_changed:before {
      content: '•';
      position: absolute;
      left: -10px;
    }
  }

  &__value {
    &_true {
      color: $--color-green;
    }

    &_false {
      color: $--color-red;
    }
  }
}
</style>
