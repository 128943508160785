import { Api } from 'lib/api'
import { getCampaignTypeBySlug } from 'lib/helpers/getCampaignTypeBySlug'
import { PlatformType } from 'lib/types/platform'
import { IResponse, IResponseData, IResponseMessage } from 'lib/types/response'
import { IStreamer } from 'lib/types/streamer'

export const setStatus = async (data: { slug: string; visible: boolean }) => {
  const type = getCampaignTypeBySlug(data.slug)
  return Api
    .post<IResponseMessage>(`artner/campaigns/${type}/ad-set/status`, data)
    .then(res => res)
}

export const setPriority = async (data: { slug: string; priority: boolean }) => {
  const type = getCampaignTypeBySlug(data.slug)
  return Api
    .post<IResponse<null>>(`partner/campaigns/${type}s/ad-set/priority`, data)
    .then(res => res)
}

export const searchStreamer = async (data: {
  platform: PlatformType
  query: string
  agencies?: number[]
  minCpm?: number
  maxCpm?: number
  campaignCategoryId?: number
  darkMarket?: boolean
}) => {
  return Api.get<IResponseData<IStreamer[]>>(`streamer/search/${data.platform}`, {
    q: data.query.toLowerCase(),
    a: data.agencies,
    min_cpm: data.minCpm,
    max_cpm: data.maxCpm,
    category: data.campaignCategoryId,
    dark_market: data.darkMarket ? '1' : '0',
  })
    .then(res => res)
}
