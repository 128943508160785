<template>
  <div
    class="log-event"
    :class="{
      'log-event_separated': isFetchingAd || isDemoCreatives,
      'log-event_new': isNew,
    }"
  >
    <ElPopover popper-class="log-event-popover-code">
      <span slot="reference">
        <span class="log-event__code-icon">&lbrace;...&rbrace;</span>
      </span>
      <pre class="log-event__code-content">{{ event }}</pre>
    </ElPopover>

    <span class="log-event__time">
      {{ event.dateTime }}
    </span>

    <span class="log-event__label">
      {{ label }}
    </span>

    <ElPopover
      v-if="isWidgetSettings"
      popper-class="log-event-popover"
    >
      <SvgIcon
        name="gear"
        width="12px"
        height="12px"
        slot="reference"
        class="log-event__icon"
      />
      <DebugWidget
        :widget="event.payload"
        :old-widget="event.oldWidget"
      />
    </ElPopover>

    <ElPopover
      v-if="hasMessages"
      popper-class="log-event-popover"
    >
      <SvgIcon
        name="dialog-bubbles"
        width="12px"
        height="12px"
        slot="reference"
        class="log-event__icon"
        :class="{'log-event__icon_error': !event.payload.status}"
      />
      <div>{{ getMessages(event) }}</div>
    </ElPopover>

    <ElPopover
      v-if="hasCreatives"
      popper-class="log-event-popover"
    >
      <SvgIcon
        name="creative"
        width="12px"
        height="12px"
        slot="reference"
        class="log-event__icon"
      />
      <div class="debug-creatives">
        <div
          v-for="item in getCreatives(event)"
          :key="item.slug"
          class="debug-creatives__item"
        >
          <a
            :href="`https://www.uplify.${$isProduction ? 'us' : 'live'}/backend/resources/ads/${item.id}`"
            target="_blank"
            class="debug-creatives__item-title"
          >
            <span>({{ item.slug || 'without slug' }})</span>
          </a>
        </div>
        <span
          class="debug-creatives__show"
          @click="copyLinkToCreatives(event)"
        >
          Copy preview link
        </span>
      </div>
    </ElPopover>
  </div>
</template>

<script>
import ElPopover from 'element/ElPopover'
import logEvents from 'views/WidgetOld/consts/logEvents'

import DebugWidget from './DebugWidget'

const logEventsLabels = {
  [logEvents.UPDATE_WIDGET]: 'Widget updated',
  [logEvents.UNDEFINED_EVENT]: 'Socket undefined event',
  [logEvents.FETCHED_CREATIVES]: 'Response for ad request',
  [logEvents.FETCHED_REFERRAL_CREATIVES]: 'Response for Promotion request',
  [logEvents.FETCHED_DEMO_CREATIVES]: 'Demo-creatives fetched',
  [logEvents.AUTO_START]: 'Start in auto mode',
  [logEvents.MANUAL_START]: 'Start in manual mode',
  [logEvents.DEMO_START]: 'Start in demo mode',
  [logEvents.FETCHING_AD]: 'Ad request',
  [logEvents.CHATBOT_MESSAGE_SENT]: 'Chatbot message sent',
}

export default {
  components: {
    ElPopover,
    DebugWidget,
  },

  props: {
    event: VueTypes.object,
  },

  data: () => ({
    labels: logEventsLabels,
    events: logEvents,
    isNew: true,
  }),

  computed: {
    label: ({ event, labels }) => labels[event.event] || 'Неизвестное событие',
    isWidgetSettings: ({ event }) => event.event === logEvents.UPDATE_WIDGET,
    isFetchedCreatives: ({ event }) => event.event === logEvents.FETCHED_CREATIVES || event.event === logEvents.FETCHED_REFERRAL_CREATIVES,
    isFetchingAd: ({ event }) => event.event === logEvents.FETCHING_AD,
    isDemoCreatives: ({ event }) => event.event === logEvents.FETCHED_DEMO_CREATIVES,
    hasCreatives ({ event, isFetchedCreatives }) {
      return isFetchedCreatives && this.getCreatives(event).length
    },
    hasMessages: ({ event }) => event?.payload?.messages,
  },

  mounted () {
    setTimeout(() => {
      this.isNew = false
    }, 1000)
  },

  methods: {
    getMessages (event) {
      if (event?.payload?.reason) {
        return event.payload.reason
      }
      return event?.payload?.messages?.map(item => item.text).join(', ')
    },
    getCreatives (event) {
      return event?.payload?.data || []
    },
    copyLinkToCreatives (event) {
      const creativesSlugs = this.getCreatives(event).map(item => item.slug)
      const url = new URL(window.location.origin + '/creative-preview')
      url.searchParams.append('creatives', creativesSlugs)
      url.searchParams.append('streamer', '')

      this.$clipboard(url.href)
    },
  },
}
</script>

<style lang="scss">
.log-event-popover {
  padding: 10px;
}

.log-event-popover-code {
  max-width: 500px;
  max-height: 90vh;
  padding: 8px 10px;
  overflow: auto;
}
</style>

<style lang="scss" scoped>
.log-event {
  display: inline-flex;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: transparent;
  border-radius: 3px;
  padding: 1px 3px;
  transition: background-color 5s linear;

  &_new {
    background-color: #ffeabb;
  }

  &__code {
    &-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      font-size: 8px;
      color: $--color-primary-light-3;
      background-color: $--color-primary-light-8;
      border-radius: 50%;
      margin-right: 5px;
      cursor: pointer;
    }

    &-content {
      font-family: monospace, 'Inter';
      font-size: 12px;
      line-height: 1.3;
    }
  }

  &__time {
    font-size: 13px;
    margin-right: 5px;
  }

  &__icon {
    fill: $--color-primary;
    cursor: pointer;
    transition: $--all-transition;
    margin-left: 5px;
    position: relative;
    top: 2px;

    &:hover {
      fill: $--color-primary-light-2;
    }

    &_error {
      fill: $--color-red;

      &:hover {
        fill: $--color-lighter-red;
      }
    }
  }

  &_separated {
    &:not(:first-child) {
      margin-top: 5px;
    }
  }
}

.debug-creatives {
  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid $--color-ash-gray;
    }

    &-title {
      white-space: nowrap;
      color: $--color-primary;

      &:hover {
        color: $--color-primary-light-3;
      }

      span {
        display: inline-block;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child {
          margin-right: 10px;
        }
      }
    }
  }

  &__show {
    color: $--color-primary;
    cursor: pointer;

    &:hover {
      color: $--color-primary-light-3;
    }

    &:active {
      color: $--color-primary-dark;
    }
  }
}
</style>
