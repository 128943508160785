function escapeRegExp (str) {
  return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&') // eslint-disable-line
}

export default ({ compressed, keys }) => {
  let result = compressed
  for (const key in keys) {
    result = result.replace(new RegExp(escapeRegExp(key), 'g'), keys[key])
  }
  return JSON.parse(result)
}
