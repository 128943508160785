<template>
  <div>
    <ElButton
      :type="messageSuccessfullySent ? 'success' : 'info'"
      :loading="sendingMessage"
      @click="sendMessage"
    >
      {{ messageSuccessfullySent ? 'Message sent' : 'Send message' }}
    </ElButton>
    <br>
    <br>
  </div>
</template>

<script>
import ElButton from 'element/ElButton'

export default {
  components: {
    ElButton,
  },

  data: () => ({
    messageSuccessfullySent: false,
  }),

  computed: {
    sendingMessage: ({ $store }) => $store.getters['widgetSettings/sendingMessage'],
  },

  watch: {
    sendingMessage (val) {
      if (!val) {
        this.messageSuccessfullySent = true
        setTimeout(() => {
          this.messageSuccessfullySent = false
        }, 1500)
      }
    },
  },

  methods: {
    sendMessage () {
      this.$store.dispatch('widgetSettings/sendMessage')
    },
  },
}
</script>
