import { ReverseMap } from 'lib/types/base-types'

export enum PusherEvents {
  WIDGET_UPDATED = '.widget.params.updated',
  MANUAL_LAUNCH = '.manual.launch',
  AUTO_LAUNCH = '.auto.launch',
  DEMO_REAL_LAUNCH = '.demo.real.launch',
  DEMO_LAUNCH = '.demo.launch',
  DEMO_REFERRAL_LAUNCH = '.demo.referral.launch',
  FETCHING_AD = '.fetching-ad',
  REFERRAL_LAUNCH = '.referral.launch',
  CHATBOT_MESSAGE_SENT = '.chatbot-message-sent',
}

export type PusherEventsType = ReverseMap<typeof PusherEvents>
