<template>
  <div class="debug-tabs">
    <ElTabs
      :value="tab"
      @input="onChange"
    >
      <ElTabPane
        v-for="item in streamers"
        :key="item.widget.slug + item.nickname"
        :label="item.nickname || item.widget.slug"
        :name="item.widget.slug"
      />
    </ElTabs>
  </div>
</template>

<script>
import ElTabPane from 'element/ElTabPane'
import ElTabs from 'element/ElTabs'

export default {
  components: {
    ElTabs,
    ElTabPane,
  },

  props: {
    streamers: VueTypes.array,
    tab: VueTypes.string,
  },

  methods: {
    onChange (value) {
      localStorage.setItem('active-debug-tab', value)
      this.$emit('update:tab', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.debug-tabs {
  margin-top: 16px;

  ::v-deep {
    .el-tabs__header {
      margin-bottom: 0;
    }
  }
}
</style>
