<template>
  <div class="debug-send-preview">
    <ElSelect
      placeholder="Select company"
      multiple
      clearable
      v-model="choosed"
    >
      <ElOption
        v-for="item in campaigns"
        :key="item.id"
        :value="item.slug"
        :label="item.title"
      />
    </ElSelect>

    <br>
    <br>
    <ElButton
      type="primary"
      @click="onClick"
    >
      {{ choosed.length ? 'Send selected' : 'Send demo' }}
    </ElButton>
  </div>
</template>

<script>
import ElButton from 'element/ElButton'
import ElOption from 'element/ElOption'
import ElSelect from 'element/ElSelect'
import { Api } from 'lib/api/instance'

export default {
  components: {
    ElSelect,
    ElOption,
    ElButton,
  },

  props: {
    slug: VueTypes.string,
  },

  data: () => ({
    campaigns: [],
    choosed: [],
  }),

  created () {
    this.fetchCampaigns()
  },

  methods: {
    async fetchCampaigns () {
      const url = `ads/widget/debug/${this.slug}/list`
      const res = await Api.get(url)

      this.campaigns = res.data
    },

    onClick () {
      if (this.choosed.length) {
        this.sendPreview()
      }
      else {
        this.sendDemo()
      }
    },

    async sendPreview () {
      const url = `ads/widget/debug/${this.slug}/real`
      const params = { ads: this.choosed }

      const res = await Api.post(url, params)

      if (res.status) {
        this.$message.success(this.$rootLocale('button.sendPreview.success'))
      }
    },

    async sendDemo () {
      const url = `ads/widget/debug/${this.slug}/demo`

      const res = await Api.get(url)

      if (res.status) {
        this.$message.success('Demo Sent')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.debug-send-preview {
  margin-top: 32px;
  margin-bottom: 16px;

  ::v-deep {
    .el-button {
      width: 100%;
    }

    .el-tag {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
