<template>
  <DashboardLayout>
    <div class="debug-widget">
      <div class="debug-widget__top">
        <DebugSelectStreamer
          v-loading="loading"
          :disabled="role === 'streamer'"
          @select="onSelect"
        />
        <DebugTabs
          :streamers="streamers"
          :tab.sync="tab"
        />
      </div>
      <DebugLog
        v-for="item in streamers"
        v-show="tab === item.widget.slug"
        :key="item.widget.slug"
        :slug="item.widget.slug"
        :is-active="tab === item.widget.slug"
        @close="onClose(item)"
        @fetched-streamer="onFetchStreamer"
      />
    </div>
  </DashboardLayout>
</template>

<script>
import loading from 'element/Loading'
import cachedLog from 'views/WidgetOld/Debug/cachedLog'
import DashboardLayout from 'components/layouts/DashboardLayout/index.vue'
import DebugLog from './DebugLog'
import DebugSelectStreamer from './DebugSelectStreamer'
import DebugTabs from './DebugTabs'

export default {
  components: {
    DashboardLayout,
    DebugSelectStreamer,
    DebugTabs,
    DebugLog,
  },

  directives: { loading },

  data: () => ({
    streamers: [],
    tab: '',
    loading: true,
  }),

  computed: {
    user: ({ $store }) => $store.getters['auth/user'],
    role: ({ $store }) => $store.getters['auth/role'],
  },

  created () {
    const user = this.$store.getters['auth/user']
    const regExp = new RegExp(process.env.VUE_APP_DEBUG_EMAILS)
    if (regExp.test(user.email)) {
      this.init()
    }
  },

  beforeCreate () {
    const user = this.$store.getters['auth/user']
    const regExp = new RegExp(process.env.VUE_APP_DEBUG_EMAILS)
    if (!regExp.test(user.email)) {
      this.$router.push({ name: '404' })
    }
  },

  methods: {
    async init () {
      this.openCachedWidgets()

      if (localStorage.getItem('active-debug-tab')) {
        this.tab = localStorage.getItem('active-debug-tab')
      }

      this.openAddressWidget()

      this.loading = false
    },
    openCachedWidgets () {
      cachedLog.clearExpired()
      const widgets = cachedLog.getCachedWidgets() || {}
      for (const slug in widgets) {
        this.onSelect(slug)
      }
    },
    openAddressWidget () {
      if (!this.$route.params.slug) return
      this.onSelect(this.$route.params.slug)
      this.$router.push({ name: 'debug' })
    },
    onSelect (slug) {
      const isChoosed = this.streamers.find(item => item.widget.slug === slug)
      if (!isChoosed) {
        this.streamers.unshift({
          widget: {
            slug,
          },
        })
      }
      this.tab = slug
    },
    onFetchStreamer (streamer) {
      this.streamers = this.streamers.map(item => {
        if (item.widget.slug === streamer.widget.slug) {
          return {
            ...streamer,
            nickname: streamer.name,
          }
        }
        return item
      })
    },
    onClose (streamer) {
      const idx = this.streamers.findIndex(item => item.nickname === streamer.nickname)
      this.streamers.splice(idx, 1)
      this.tab = this.streamers[0]?.widget?.slug || ''
    },
  },
}
</script>

<style lang="scss" scoped>
.debug-widget {
  min-height: 80vh;

  &__top {
    position: fixed;
    width: calc(100% - 200px);
    background-color: white;
    left: 200px;
    top: 0;
    padding: 32px 40px 0;
    z-index: 2;
  }
}
</style>
