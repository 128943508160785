<template>
  <div
    v-loading="loading"
    class="debug-log"
  >
    <div
      v-if="streamer"
      class="debug-log__left"
    >
      <div class="debug-log__left-streamer">
        <b>{{ slug }}</b>
        ({{ streamer.balance | toCurrency(true, streamer.currency) }})
      </div>
      <DebugWidget
        :widget="widget"
      />
      <DebugSendPreview :slug="slug" />
      <DebugChatbotSendMessage v-show="chatbotConnected && isStreamer" />
      <ElButton
        type="danger"
        @click="close"
      >
        Stop watching
      </ElButton>
    </div>

    <NoStreamer
      v-if="!hasStreamer"
      @close="close"
    />

    <div
      v-if="streamer"
      class="debug-log__right"
    >
      <div
        ref="debug-log-log"
        class="debug-log__log"
      >
        <div class="debug-log__log-inner">
          <div class="debug-log__log-actions">
            <span
              v-if="log.length"
              class="debug-log__log-copy"
              @click="copyLog"
            >
              Copy log
            </span>

            <ElPopover
              v-model="visiblePopover"
              trigger-click
            >
              <span
                slot="reference"
                class="debug-log__log-copy"
              >
                Paste log
              </span>
              <div>
                <ElInput
                  v-model="inputLog"
                  ref="inputLog"
                  type="textarea"
                  @keyup.enter.native.prevent="pasteLog"
                />
              </div>
            </ElPopover>
          </div>
          <template v-if="log.length">
            <DebugWidgetLogEvent
              v-for="item in log"
              :key="item.label"
              :event="item"
            />
          </template>
          <div
            v-else
            class="debug-log__log-none"
          >
            There were no events yet
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ElButton from 'element/ElButton'
import ElInput from 'element/ElInput'
import ElPopover from 'element/ElPopover'
import loading from 'element/Loading'
import { payloadToWidgetAdapter } from 'lib/adapters/widgetPayloadAdapter'
import { Api } from 'lib/api/instance'
import { getRole } from 'lib/helpers/authToken'
import { pusher } from 'lib/pusher'
import * as pusherEvents from 'lib/pusher/constants/pusher-events'
import WidgetLogger from 'views/Widget/Debug/class/WidgetLogger'

import DebugChatbotSendMessage from './DebugChatbotSendMessage'
import DebugSendPreview from './DebugSendPreview'
import DebugWidget from './DebugWidget'
import DebugWidgetLogEvent from './DebugWidgetLogEvent'
import NoStreamer from './NoStreamer'

export default {
  components: {
    DebugWidget,
    DebugWidgetLogEvent,
    DebugSendPreview,
    ElPopover,
    ElInput,
    ElButton,
    NoStreamer,
    DebugChatbotSendMessage,
  },

  directives: { loading },

  props: {
    slug: VueTypes.string,
  },

  data: () => ({
    hasStreamer: true,
    loading: true,
    widget: null,
    streamer: null,
    socket: null,
    log: [],
    inputLog: '',
    visiblePopover: false,
    isStreamer: false,
    logger: null,
  }),

  computed: {
    mode: ({ widget }) => widget?.advertising.mode,
    chatbotConnected: ({ widget }) => widget?.nightbot?.[widget.platform]?.connected,
  },

  watch: {
    visiblePopover (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.inputLog.focus()
        })
      }
    },
    mode (val) {
      this.logger.setMode(val)
    },
  },

  created () {
    this.isStreamer = getRole() === 'streamer'
    this.init()
  },

  methods: {
    async init () {
      try {
        this.logger = new WidgetLogger(this.slug, { onPushLog: this.onPushLog })
        this.log = this.logger.value
        this.fetchDebug()
      }
      catch {
        this.hasStreamer = false
      }
      finally {
        this.loading = false
      }
    },
    async fetchDebug () {
      const result = await Api.get(`ads/widget/${this.slug}/debug`)
      if (result.status) {
        this.widget = payloadToWidgetAdapter(result)
        this.streamer = this.widget.streamer
        this.$emit('fetched-streamer', {
          ...this.streamer,
          widget: { slug: this.slug },
        })
        this.initPusher()
      }
    },
    initPusher () {
      const { echo } = pusher(this.slug)
      const channel = `uplify.ads.${this.slug}`

      echo.private(channel)
        .listen(pusherEvents.WIDGET_UPDATED, (payload) => {
          this.logger.handleEvent(pusherEvents.WIDGET_UPDATED, payload)
        })
        .listen(pusherEvents.MANUAL_LAUNCH, async (payload) => {
          this.logger.handleEvent(pusherEvents.MANUAL_LAUNCH, payload)
        })
        .listen(pusherEvents.AUTO_LAUNCH, (payload) => {
          this.logger.handleEvent(pusherEvents.AUTO_LAUNCH, payload)
        })
        .listen(pusherEvents.REFERRAL_LAUNCH, (payload) => {
          this.logger.handleEvent(pusherEvents.REFERRAL_LAUNCH, payload)
        })
        .listen(pusherEvents.DEMO_LAUNCH, (payload) => {
          this.logger.handleEvent(pusherEvents.DEMO_LAUNCH, payload)
        })
        .listen(pusherEvents.CHATBOT_MESSAGE_SENT, (payload) => {
          this.logger.handleEvent(pusherEvents.CHATBOT_MESSAGE_SENT, payload)
        })
        .listen(pusherEvents.FETCHING_AD, (payload) => {
          this.logger.handleEvent(pusherEvents.FETCHING_AD, payload)
        })
    },
    onPushLog () {
      this.$nextTick(() => {
        window.scrollTo(0, document.body.scrollHeight)
      })
    },
    close () {
      this.logger.deleteLog()
      this.$emit('close')
    },
    copyLog () {
      const encodedLog = JSON.stringify(this.log)
      this.$clipboard(encodedLog)
    },
    pasteLog () {
      const log = JSON.parse(this.inputLog.replace(/\s+/i, ''))
      this.visiblePopover = false
      this.inputLog = ''

      this.log = log // watcher won't trigger without this line
      this.logger.log = log
    },
  },
}
</script>

<style lang="scss" scoped>
.debug-log {
  display: flex;
  justify-content: flex-end;
  padding-top: 120px;

  &:before {
    content: '';
    width: calc(100% - 200px);
    height: 25px;
    background: linear-gradient(white, rgba(255, 255, 255, 0));
    position: fixed;
    top: 126px;
    left: 200px;
    z-index: 2;
  }

  &:after {
    content: '';
    width: calc(100% - 200px);
    height: 50px;
    background: linear-gradient(rgba(255, 255, 255, 0), white);
    position: fixed;
    bottom: 0;
    left: 200px;
    z-index: 2;
  }

  &__left {
    position: fixed;
    left: 240px;

    &-streamer {
      display: block;
      font-size: 16px;
      margin-bottom: 10px;

      b {
        font-size: inherit;
      }
    }

    ::v-deep {
      .el-button {
        width: 100%;
      }
    }
  }

  &__right {
    width: 350px;
    z-index: 1;
    padding-bottom: 100px;
  }

  &__log {
    position: relative;

    &-actions {
      margin-bottom: 10px;
    }

    &-copy {
      display: inline-block;
      color: $--color-primary;
      cursor: pointer;
      margin-right: 20px;
    }

    * {
      line-height: 1.4;
    }
  }

  &__demo {
    margin-top: 32px;
  }
}
</style>
