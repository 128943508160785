export default {
  UPDATE_WIDGET: 'UPDATE_WIDGET',
  UNDEFINED_EVENT: 'UNDEFINED_EVENT',
  FETCHED_CREATIVES: 'FETCHED_CREATIVES',
  FETCHED_REFERRAL_CREATIVES: 'FETCHED_REFERRAL_CREATIVES',
  FETCHED_DEMO_CREATIVES: 'FETCHED_DEMO_CREATIVES',
  AUTO_START: 'AUTO_START',
  MANUAL_START: 'MANUAL_START',
  DEMO_START: 'DEMO_START',
  FETCHING_AD: 'FETCHING_AD',
  CHATBOT_MESSAGE_SENT: 'CHATBOT_MESSAGE_SENT',
}
